import { GETINBOX, GETINBOXDETAILS, PROCESS_TRACK } from "./urls";
const token = localStorage.getItem("token")


export async function listInbox(category = 'completed') {
  const token = localStorage.getItem('token'); // Make sure to retrieve the token properly

  // Append the category as a query parameter to the URL
  const url = `${GETINBOX}?category=${category}`;

  console.log(url);

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,  // Make sure token is retrieved and added
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch inbox items");
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching inbox:", error);
      throw error;
    });
}


export async function inboxDetails(id: any) {
  return fetch(`${GETINBOXDETAILS}${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function createTrack(formData: any) {
  try {
    const response = await fetch(PROCESS_TRACK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(formData), // Sending the form data as JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error tracking process:", error);
    throw error; // Handle errors appropriately
  }
}


// code for update track
// export async function updateTrack(formData: any) {
//   try {
//     const url = `${GETINBOXDETAILS}${formData.uuid}/`;
//     const response = await fetch(url, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Token ${token}`,
//       },
//       body: JSON.stringify(formData), // Sending the form data as JSON
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) { 
//     console.error("Error tracking process:", error);
//     throw error; // Handle errors appropriately
//   }
// }

/**
 * Updates an inbox item with the given formData and files (if any)
 * @param {object} formData - Form data (non-file fields)
 * @param {object} files - Object of file inputs and their corresponding File objects
 * @returns {Promise<object>} - Response data (JSON)
 */
export async function updateTrack(formData: any, files: any) {
  try {
    const url = `${GETINBOXDETAILS}${formData.uuid}/`;

    // Create a new FormData object
    const form = new FormData();

    // Append form fields (non-file fields) to FormData
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        // Skip files as they will be handled separately
        form.append(key, formData[key]);
      }
    }

    // Append file data (files should be an object where keys are the file inputs and values are file objects)
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        form.append(key, files[key]);  // 'files[key]' should be a File object
      }
    }

    // Send the PUT request with FormData
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Authorization": `Token ${token}`,
        // Note: Content-Type will automatically be set to multipart/form-data when using FormData
      },
      body: form,  // Sending the FormData (multipart)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error tracking process:", error);
    throw error; // Handle errors appropriately
  }
}


