const token = localStorage.getItem("token");
export const BASE_URL = "https://godfather123.pythonanywhere.com";
export const AUTH_TOKEN = `Token ${token}`;
export const ORGANIZATION_HEIRARCHY = `${BASE_URL}/organization/hierarchy/`;
export const ORGANIZATION_HEIRARCHY_EDGE = `${BASE_URL}/organization/edge/`;
export const WORK_FLOW_NODE = `${BASE_URL}/workflow/node/`;
export const WORK_FLOW_EDGE = `${BASE_URL}/workflow/edge/`;
export const RESIZABLE_NODE = `${BASE_URL}/workflow/background/`;

export const WORK_FLOW_PROCESS = `${BASE_URL}/workflow/process/`;
export const WORK_FLOW_CATEGORIES = `${BASE_URL}/workflow/category/`;
export const WORK_FLOW_REQUEST = `${BASE_URL}/workflow/request/`;
export const WORK_FLOW_FORM = `${BASE_URL}/workflow/form/`;
export const WORK_CREATE_TRACK = `${BASE_URL}/workflow/create-track/`;
export const LOGIN = `${BASE_URL}/login/`;
export const ORGANIZATION_STAFF = `${BASE_URL}/organization/staff`;
export const GETINBOX = `${BASE_URL}/workflow/inbox`;
// export const GETINBOXDETAILS = `${BASE_URL}/workflow/inbox-detail/`;
export const GETINBOXDETAILS = `${BASE_URL}/workflow/inbox/`;

export const PROCESS_TRACK = `${BASE_URL}/workflow/track/`;
 