import { Typography } from "@mui/material";
import { MdOutlineSearch } from "react-icons/md";

interface InputProps {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeHolder?: string;
  label?: string;
  prepend?: React.ReactNode;
}

export function Input({
  name,
  placeHolder,
  onChange,
  label,
  prepend,
}: InputProps) {
  return (
    <div className="flex flex-col w-full">
      {label && <Typography variant="subtitle1">{label}</Typography>}
      <div
        className={`border border-gray-400 rounded-md my-2 w-full overflow-hidden flex flex-row items-center ${
          prepend ? "pl-2" : ""
        }`}
      >
        {prepend && <span className="mr-2">{prepend}</span>} {/* Add margin for proper spacing */}
        <input
          type="text"
          name={name}
          placeholder={placeHolder || ""}
          className="w-full p-2"
          onChange={onChange} // Handle onChange
        />
      </div>
    </div>
  );
}

export default Input;
