import React, { FC } from "react";

interface InputFieldInterface {
  value?: string;
  label: string;
  placeholder?: string; // Make placeHolder optional
  onChange?: (value: string) => void; // Make onChange optional
  onBlur?: (Value: string) => void; // Keep onBlur for focus out updates
}

const InputField: FC<InputFieldInterface> = ({
  value = "", // Default to empty string if value is not provided
  label,
  placeholder = "Enter the value here",
  onChange,
  onBlur,
}) => {
  return (
    <div>
      <label className="block text-gray-700 text-sm mb-2 capitalize">
        {label} 
      </label>
      <input
        value={value}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value)} 
        onBlur={(e) => onBlur && onBlur(e.target.value)} // Add onBlur to handle blur events
      />
    </div>
  );
};

export default InputField;
