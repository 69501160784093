import { useState } from "react";
import SideMenuItem from "./SideMenuItemNew";
import ArrowIcon from "../Icons/ArrowIcon";
import { HomeOutlined, InboxOutlined, AccountTreeOutlined } from "@mui/icons-material";
import InboxIcon from '@mui/icons-material/Inbox';

export default function SideMenu({
  toggleMenu,
  setToggleMenu,
}: {
  toggleMenu: boolean;
  setToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      className={`bg-white shadow-lg h-full py-2 transition-all duration-300 ease-in-out ${
        !toggleMenu ? "w-[250px]" : "w-[83px]"
      } border-r border-gray-300`}
    >
      {/* Toggle button */}
      <div className="px-4 border-b border-gray-300 flex items-center justify-between">
        <button
          className="flex flex-row py-4 mx-3 items-center"
          onClick={() => setToggleMenu((prev) => !prev)}
        >
          <ArrowIcon
            className={`fill-gray-600 transform ${
              toggleMenu ? "rotate-180" : ""
            } transition-transform duration-300`}
          />
          {!toggleMenu && (
            <h4 className="pl-2 font-semibold text-gray-700">Menu</h4>
          )}
        </button>
      </div>

      {/* Menu items */}
      <ul className="mt-4 space-y-2">
        <SideMenuItem
          label="Home"
          icon={<HomeOutlined className="text-gray-500" />}
          toggleMenu={toggleMenu}
          path="/home"
        />
        <SideMenuItem
          label="Requests"
          icon={<InboxOutlined className="text-gray-500" />}
          toggleMenu={toggleMenu}
          path="/inbox/inprogress"
          submenu={[
            { label: "New", route: "/inbox/inprogress", icon: <span><InboxIcon /></span> },
            { label: "Completed", route: "/inbox/completed", icon: <span>✔️</span> },
          ]}
        />
        <SideMenuItem
          label="Flow Builder"
          icon={<AccountTreeOutlined className="text-gray-500" />}
          toggleMenu={toggleMenu}
          path="/process-list"
        />
      </ul>
    </div>
  );
}
