import React, { useState } from "react";
import SideMenu from "../components/SideMenu/SideMenu";
import { FormattedMessage } from "react-intl";
import logo from "../assets/logo.png";
import { Logout as LogoutIcon } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
  Typography,
  Divider,
} from "@mui/material";
import { Logout, NotificationsOutlined } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router";
import LangDropDown from "../components/Dropdown/LangDropDown";
import useGlobalContext from "../context/useGlobalContext";
import AIworkFlowIndex from "../containers/aiworkflow/AIworkFlowIndex";

const MainLayout: React.FC<{
  locale: string;
  handleLocaleChange: (updatedLocale: string) => void;
}> = ({ locale, handleLocaleChange }) => {
  const [anchorEl, setAnchor] = useState<null | HTMLElement>(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  const { toggleAisideBox, aiSideBox } = useGlobalContext();
  const theme = useTheme();

  const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchor(null);
  };

  const handleOnClick = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleLocaleChange(e.target.value);
  };

  const profileImg = localStorage.getItem("profile_img");
  const userName = localStorage.getItem("username") || "Default Name"; // Fetch from local storage or set default
  const userDesignation = localStorage.getItem("designation") || "Default Designation"; // Fetch from local storage or set default

  return (
    <div
      className="w-screen h-screen flex flex-col"
      dir={locale === "en" ? "ltr" : "rtl"}
    >
      {/* Header */}
      <header>
        <div
          className="h-16 border-b-[1px] border-gray-300 flex justify-between px-4"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            color: theme.palette.primary.contrastText,
          }}
        >
          <div
            className={`flex items-center ${
              toggleMenu ? "w-[83px]" : "min-w-[200px] w-[25%] max-w-[250px]"
            }`}
          >
            <img
              src={logo}
              alt="Company Logo"
              style={{
                width: "120px",
                height: "40px",
                marginRight: "10px",
                objectFit: "contain",
              }}
            />
            {!toggleMenu && <FormattedMessage id="work_flow_builder" />}
          </div>
          <div className="flex items-center gap-4">
            <button
              className="ai-workflow-button bg-white text-primary font-semibold py-1 px-4 rounded shadow"
              onClick={() => toggleAisideBox((state: boolean) => !state)}
            >
              Ai Workflow Builder
            </button>
            <LangDropDown handleOnClick={handleOnClick} />
            <Badge color="secondary" variant="dot">
              <NotificationsOutlined sx={{ color: theme.palette.primary.main }} />
            </Badge>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleMenuOpen}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={anchorEl ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl)}
              >
                <Avatar
                  src={profileImg || ""}
                  sx={{ width: 32, height: 32, bgcolor: "primary.main" }}
                >
                  {!profileImg && "M"}
                </Avatar>
              </IconButton>
            </Tooltip>
            <MenuPopup
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              userName={userName}
              userDesignation={userDesignation}
            />
          </div>
        </div>
      </header>

      <div className="h-full flex">
        <SideMenu toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
        <main
          style={{
            width: `calc(100% - ${!toggleMenu ? "250px" : "83px"} - ${
              aiSideBox ? "410px" : "0px"
            })`,
          }}
        >
          <Outlet />
        </main>
        <AIworkFlowIndex />
      </div>
    </div>
  );
};

export default MainLayout;

type MenuPopupProps = {
  handleMenuClose: () => void;
  anchorEl: null | HTMLElement;
  userName: string;
  userDesignation: string;
};

function MenuPopup({ handleMenuClose, anchorEl, userName, userDesignation }: MenuPopupProps) {
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("designation");
    localStorage.removeItem("profile_img");
    navigate("/login");
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 3,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleMenuClose}>
        {/* <Avatar sx={{ bgcolor: "primary.main", width: 32, height: 32 }} /> */}
        <div style={{ marginLeft: 10 }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {userName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userDesignation}
          </Typography>
        </div>
      </MenuItem>
      <Divider />
      <MenuItem onClick={logout}>
        <LogoutIcon sx={{ color: "error.main", fontSize: 20, marginRight: 1 }} />
        <Typography variant="body2">Logout</Typography>
      </MenuItem>
    </Menu>
  );
}
