import { BASE_URL, WORK_FLOW_CATEGORIES, 
        WORK_FLOW_PROCESS, WORK_FLOW_REQUEST,
        WORK_CREATE_TRACK } from "./urls";
const token = localStorage.getItem("token");
export async function listProcess() {
  return fetch(WORK_FLOW_PROCESS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function createProcess(formData: any) {
  try {
    const response = await fetch(WORK_FLOW_PROCESS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function listCategories() {
  return fetch(WORK_FLOW_CATEGORIES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}


// export async function listCategories() {
//   await fetch(`${BASE_URL}/workflow/category/`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     },
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       console.log(data)
//       return data;
//     });
// }

export async function processRequest(payload: any) {
  return await fetch(WORK_FLOW_REQUEST, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function processDetail(processId: string) {
  const url = `${WORK_FLOW_PROCESS}${processId}`
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    // body: JSON.stringify(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });
}

export async function createTrack(
  processId: string,
  actionUuid: string,
  nodeUuid: string,
  formState: any
) {
  const url = `${WORK_CREATE_TRACK}`;
  const formData = new FormData();

  // Append the non-file fields to FormData
  formData.append("processId", processId);
  formData.append("actionUuid", actionUuid);
  formData.append("nodeUuid", nodeUuid);

  // Append each field in formState to FormData, handling files and undefined values
  Object.entries(formState).forEach(([key, value]) => {
    if (value !== undefined) {
      if (value instanceof File) {
        // If the value is a File, add it directly
        formData.append(key, value);
      } else {
        // If it's not a file, convert objects to JSON strings, otherwise add as string
        formData.append(key, typeof value === "object" ? JSON.stringify(value) : value.toString());
      }
    }
  });

  // Send the FormData using fetch with the required headers
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`, // Only set authorization; Content-Type is handled by FormData
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error in createTrack:", error);
    throw error;
  }
}

