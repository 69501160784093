import { useState } from "react";
import { Collapse } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function SideMenuItem({
  label,
  icon,
  toggleMenu,
  path,
  submenu,
}: {
  label: string;
  icon: JSX.Element;
  toggleMenu: boolean;
  path: string;
  submenu?: { label: string; route: string; icon?: JSX.Element }[];
}) {
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const location = useLocation(); // Get current path for active styling
  const navigate = useNavigate(); // To programmatically navigate

  const handleSubmenuClick = () => {
    setSubmenuOpen((state) => !state);
    if (!submenu) {
      navigate(path);
    }
  };

  return (
    <>
      {/* Main menu item */}
      <li
        className={`group mx-3 mt-1 mb-2 items-center hover:bg-[#0060abb3] rounded-md px-4 cursor-pointer ${
          path === location.pathname || submenu?.some((sub) => sub.route === location.pathname)
            ? "bg-[#0060AB] text-white"
            : "hover:bg-gray-100 text-[#4d4d4d]"
        }`}
        onClick={handleSubmenuClick}
      >
        <div className="flex flex-row items-center py-2">
          <div className={path === location.pathname ? "text-white" : "text-[#757575]"}>
            {icon}
          </div>
          <Typography
            variant="h5"
            color="inherit"
            className={`pl-2 ${!toggleMenu ? "opacity-100" : "opacity-0 text-[0px]"}`}
          >
            {label}
          </Typography>
        </div>
      </li>

      {/* Submenu items */}
      {!toggleMenu && submenu && (
        <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
          <ul className="ml-[50px]">
            {submenu.map((subItem, index) => (
              <li
                key={index}
                className={`flex items-center px-3 py-2 rounded-md cursor-pointer hover:bg-gray-100 transition-all duration-200 ${
                  location.pathname === subItem.route ? "text-[#0060AB]" : "text-gray-600"
                }`}
                onClick={() => {
                  navigate(subItem.route, { state: { _display: false } });
                  console.log(`Submenu item clicked: ${subItem.label}`);
                }}
              >
                {subItem.icon && <span className="mr-2">{subItem.icon}</span>}
                <Typography variant="subtitle1">
                  &#x2022; {subItem.label}
                </Typography>
              </li>
            ))}
          </ul>
        </Collapse>
      )}
    </>
  );
}
