import React, { FC, useState } from "react";
import Heading from "./Heading";
import InputField from "./InputField";
// import CheckBox from "./CheckBox";
import { elements_type } from "../constants";

interface TextFieldInterface {
  onDelete: () => void;
  // onChange: (data: any, call_api?: boolean) => void;
  onBlur: (value: string, field: string) => void;
  label: string;
  required?: boolean;
  hidden?: boolean;
  readonly?: boolean;
}

const FormCheckBox: FC<TextFieldInterface> = ({
  onDelete,
  // onChange,
  onBlur,
  label,
}) => {
  const [data, setData] = useState({
    label,
    element_type: "INP",
    input_type: elements_type.CHECKBOX,
    required: true,
    hidden: false,
    readonly: false,
  });

  const handleChange = (value: string, fieldType: 'label' | 'placeholder') => {
    console.log("OnChnage is called@", value, fieldType);
    const updatedData = {
      ...data,
      [fieldType]: value, // Dynamically update either label or placeholder
    };
    setData(updatedData);
    console.log(updatedData); // Log the updated data
  };

  return (
    <div className="border-[1px] rounded bg-white">
      <Heading type={elements_type.CHECKBOX} onDelete={onDelete} />
      <div className="p-4">
        {/* Update label and value onBlur */}
        <InputField
          label="Label"
          placeholder="Label"
          value={data.label}
          onChange={(value) => {
            handleChange(value, 'label'); // No API call on change
          }}
          onBlur={(e) => onBlur(e, 'label')}
        />
        {/* <InputField
          label="value"
          id="value"
          value={data.value}
          placeHolder="Value"
          onChange={(value) => updateData("value", value, false)} // Update on change
          onBlur={() => updateData("value", data.value || "")} // Trigger update on blur
        /> */}
      </div>

      <div className="flex pl-4 pr-4 pb-4 justify-between">
        {/* These CheckBoxes will trigger update immediately on change */}
        {/* <CheckBox
          label="Required"
          isChecked={data.required}
          onChange={(e) => updateData("required", e.target.checked)}
        />
        <CheckBox
          label="Read Only"
          isChecked={data.readonly}
          onChange={(e) => updateData("readonly", e.target.checked)}
        />
        <CheckBox
          label="Hidden"
          isChecked={data.hidden}
          onChange={(e) => updateData("hidden", e.target.checked)}
        /> */}
      </div>
    </div>
  );
};

export default FormCheckBox;
