import React, { useState, useEffect } from 'react';
import './DynamicForm.css';

const DynamicFormPopup = ({ formData, actionList, onClose, onSubmit }) => {
    const [formState, setFormState] = useState({});
    const [selectedAction, setSelectedAction] = useState('');

    // Set today's date for any field of type "DATE"
    useEffect(() => {
        const initialFormState = {};
        formData.forEach(field => {
            if (field.type === 'DATE') {
                initialFormState[field.id] = new Date().toISOString().split('T')[0]; // Set to today's date
            }
        });
        setFormState(prev => ({ ...prev, ...initialFormState }));
    }, [formData]);

    const handleChange = (id, value) => {
        setFormState(prev => ({ ...prev, [id]: value }));
    };

    const handleActionChange = (e) => {
        setSelectedAction(e.target.value); // Stores only the uuid of selected action
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formState, selectedAction);
        onSubmit(formState, selectedAction);  // Pass formState and selectedAction to parent component
      };
      

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>×</button>
                <h2>{formData.name}</h2>
                <p>{formData.description}</p>
                <form onSubmit={handleSubmit}>
                    {formData.map(field => (
                        <div key={field.id} className="form-group">
                            {field.type === "TITLE" && (
                                <h3 className="form-title">{field.title}</h3>
                            )}
                            {field.type === "TEXT_FIELD" && (
                                <>
                                    <label htmlFor={field.id}>{field.label}</label>
                                    <input
                                        type="text"
                                        name={field.label} // Set name as label
                                        id={field.id}
                                        placeholder={field.placeholder}
                                        onChange={(e) => handleChange(field.id, e.target.value)}
                                    />
                                </>
                            )}
                            {field.type === "FILE_UPLOAD" && (
                                <>
                                    <label htmlFor={field.id}>{field.label}</label>
                                    <input
                                        type="file"
                                        name={field.label} // Set name as label
                                        id={field.id}
                                        onChange={(e) => handleChange(field.id, e.target.files[0])}
                                    />
                                </>
                            )}
                            {field.type === "DROP_DOWN" && (
                                <>
                                    <label htmlFor={field.id}>{field.label}</label>
                                    <select
                                        name={field.label} // Set name as label
                                        id={field.id}
                                        onChange={(e) => handleChange(field.id, e.target.value)}
                                    >
                                        {field.options.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </>
                            )}
                            {field.type === "RADIO_BUTTON" && (
                                <>
                                    <label>{field.label}</label>
                                    <div className="radio-group">
                                        {field.options.map((option, index) => (
                                            <label key={index}>
                                                <input
                                                    type="radio"
                                                    name={field.label} // Set name as label for group
                                                    value={option}
                                                    onChange={() => handleChange(field.id, option)}
                                                />
                                                {option}
                                            </label>
                                        ))}
                                    </div>
                                </>
                            )}
                            {field.type === "CHECKBOX" && (
                                <div className="checkbox-group">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name={field.label} // Set name as label
                                            onChange={(e) => handleChange(field.id, e.target.checked)}
                                        />
                                        {field.label}
                                    </label>
                                </div>
                            )}
                            {field.type === "TOGGLE" && (
                                <>
                                    <label>{field.label}</label>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            name={field.label} // Set name as label
                                            id={`toggle-${field.id}`}
                                            onChange={(e) => handleChange(field.id, e.target.checked)}
                                        />
                                        <label htmlFor={`toggle-${field.id}`} className="toggle-label"></label>
                                    </div>
                                </>
                            )}
                            {field.type === "DATE" && (
                                <>
                                    <label htmlFor={field.id}>{field.label}</label>
                                    <input
                                        type="date"
                                        name={field.label} // Set name as label
                                        id={field.id}
                                        value={formState[field.id] || ''} // Set the value to today's date
                                        onChange={(e) => handleChange(field.id, e.target.value)}
                                    />
                                </>
                            )}
                        </div>
                    ))}

                {/* Separate Action Section */}
                <div className="action-section">
                    <p className="action-description">Please select the action to be taken:</p>
                    <select id="action-select" value={selectedAction} onChange={handleActionChange}>
                        <option value="">Select an action</option>
                        {actionList?.map((action, index) => (
                            <option key={index} value={action.uuid}>{action.name}</option>
                        ))}
                    </select>
                    
                    <div className="button-container">
                        <button onClick={handleSubmit} type="button" className="action-button">Forward</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    );
};

export default DynamicFormPopup;
