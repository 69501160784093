import React, { FC, useState, useEffect } from "react";
import Heading from "./Heading";
import InputField from "./InputField";
import CheckBox from "./Addons/CheckBox";
import { elements_type } from "../constants";

interface TextFieldInterface {
  label: string;
  placeholder: string;
  isRequired: boolean;
  isShowForAllStages: boolean;
  onDelete: () => void;
  onBlur: (value: string, field: string) => void;
  onCheckboxChange: (isRequired: boolean, isShowForAllStages: boolean) => void;
}

const TextField: FC<TextFieldInterface> = ({
  label = "",
  placeholder = "",
  isRequired,
  isShowForAllStages,
  onDelete,
  onBlur,
  onCheckboxChange, // New callback prop
}) => {
  const [data, setData] = useState({
    label: label || "",
    placeholder: placeholder || "",
    element_type: "INP",
    input_type: elements_type.TEXTFIELD,
  });

  // Rename state variables to avoid conflict
  const [localIsRequired, setLocalIsRequired] = useState(isRequired);
  const [localIsShowForAllStages, setLocalIsShowForAllStages] = useState(isShowForAllStages);

  const handleChange = (value: string, fieldType: 'label' | 'placeholder') => {
    const updatedData = {
      ...data,
      [fieldType]: value,
    };
    setData(updatedData);
    onBlur(value, fieldType); // Notify parent of label/placeholder changes
  };

  // Notify parent component of checkbox changes
  useEffect(() => {
    onCheckboxChange(localIsRequired, localIsShowForAllStages);
  }, [localIsRequired, localIsShowForAllStages, onCheckboxChange]);

  return (
    <div className="border-[1px] rounded-md bg-white">
      <Heading type={elements_type.TEXTFIELD} onDelete={onDelete} />
      <div className="p-4">
        <InputField
          label="Label"
          placeholder={data.label}
          value={data.label}
          onBlur={(e) => onBlur(e, 'label')}
          onChange={(e) => handleChange(e, 'label')}
        />
        <InputField
          label="Placeholder"
          placeholder={data.placeholder}
          value={data.placeholder}
          onBlur={(e) => onBlur(e, 'placeholder')}
          onChange={(e) => handleChange(e, 'placeholder')}
        />
        <div className="flex justify-between">
          <CheckBox 
            label="Required" 
            isChecked={localIsRequired} 
            onChange={() => setLocalIsRequired(!localIsRequired)}
          />
          <CheckBox 
            label="Show for all stages" 
            isChecked={localIsShowForAllStages} 
            onChange={() => setLocalIsShowForAllStages(!localIsShowForAllStages)}
          />
        </div>
      </div>
    </div>
  );
};

export default TextField;
