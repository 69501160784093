import React from 'react';
import { Typography } from "@mui/material";

interface DropdownProps {
  name: string;
  label?: string;
  options: { value: any; label: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  prepend?: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ name, label, options, onChange, prepend }) => {
  return (
    <div className="flex flex-col w-full">
      {label && <Typography variant="subtitle1">{label}</Typography>}
      <div
        className={`border border-gray-400 rounded-md my-2 w-full overflow-hidden flex items-center ${
          prepend ? "pl-2" : ""
        }`}
      >
        {prepend && <span className="mr-2">{prepend}</span>}
        <select
          id={name}
          name={name}
          onChange={onChange}
          className="w-full p-2 text-base text-gray-700 bg-white focus:outline-none"
        >
          <option value="">Select an option</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
