import {
  MdDynamicFeed,
  MdOutlineArrowDropDownCircle,
  MdOutlineCalendarToday,
  MdOutlineCheckBox,
  MdOutlineFileUpload,
  MdOutlineRadioButtonChecked,
  MdOutlineToggleOn,
  MdTextFields,
  MdTitle,
} from "react-icons/md";

export const elements_type = {
  TITLE: "TITLE",
  TEXTFIELD: "TEXT_FIELD",
  CHECKBOX: "CHECKBOX",
  DROPDOWN: "DROP_DOWN",
  FILEUPLOAD: "FILE_UPLOAD",
  DATE: "DATE",
  RADIOBUTTON: "RADIO_BUTTON",
  MULTIFILEUPLOAD: "MULTI_FILE_UPLOAD",
  TOGGLE: "TOGGLE",
  STAGE: "STAGE",
};

export const elements = [
  {
    type: elements_type.TITLE,
    label: "Title",
    icon: MdTitle,
  },
  {
    type: elements_type.TEXTFIELD,
    label: "Text Field",
    icon: MdTextFields,
  },
  {
    type: elements_type.CHECKBOX,
    label: "Check Box",
    icon: MdOutlineCheckBox,
  },
  {
    type: elements_type.DROPDOWN,
    label: "Drop Down",
    icon: MdOutlineArrowDropDownCircle,
  },
  {
    type: elements_type.FILEUPLOAD,
    label: "File Upload",
    icon: MdOutlineFileUpload,
  },
  {
    type: elements_type.DATE,
    label: "Date",
    icon: MdOutlineCalendarToday,
  },
  // {
  //   type: elements_type.RADIOBUTTON,
  //   label: "Radio Button",
  //   icon: MdOutlineRadioButtonChecked,
  // },
  // {
  //   type: elements_type.MULTIFILEUPLOAD,
  //   label: "Multi File Upload",
  //   icon: MdOutlineFileUpload,
  // },
  {
    type: elements_type.TOGGLE,
    label: "Toggle",
    icon: MdOutlineToggleOn,
  },
  // {
  //   type: elements_type.STAGE,
  //   label: "Stage",
  //   icon: MdDynamicFeed,
  // },
];
