import { useCallback, useEffect, useState } from "react";
import { InboxCategory } from "../../components/inbox/InboxCategory/InboxCategory";
import InboxSection from "../../components/inbox/InboxSection/InboxSection";
import { HistoryIcon } from "../../components/inbox/icons/HistoryIcon";
import { InboxIcon } from "../../components/inbox/icons/InboxIcon";
import { ProcessingIcon } from "../../components/inbox/icons/ProcessingIcon";
import { inboxSections } from "../../utils/inbox";
import "./inbox.css";
import {
  MdAdd,
  MdFilterList,
  MdInbox,
  MdOutlineAccessTime,
  MdOutlineCreateNewFolder,
  MdOutlineSearch,
  MdOutlineTaskAlt,
} from "react-icons/md";
import Input from "../../components/Input/Input";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import InputField from "../../components/FormElements/components/InputField";
import CheckBox from "../../components/FormElements/components/CheckBox";
import { inboxDetails, listInbox, updateTrack } from "../../apis/inbox";
import { Button } from "../../components/Button/Button";
import EmailDetailCard from "./inboxDetail";
import { useLocation } from 'react-router-dom';

export function Inbox(category: any) {
  const [activeCatagoryIndex, setActiveCategoryIndex] = useState<number>(1);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [inboxList, setinboxList] = useState<any>([]);
  const [selectedInboxDetails, setSelectedInboxDetails] = useState<any>({});
  const [commonData, setCommonData] = useState<any>({});
  const [historicData, setHistoricData] = useState<any>([]);
  const [dialog, setDialog] = useState(false);
  const [dialogItem, setDialogItem] = useState<any>(null); 
  const [actionId, setActionId] = useState<any>(null); 
  const location = useLocation();

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const setIsShowHandler = () => {
    // set location state display as true
    console.log("location.state?.display: ", location.state?.display);
    console.log("IsShow: ", isShow);
    // if (location.state?.display) {
    //   setIsShow(false);
    // }else{
    //   location.state = { display: true };
    //   setIsShow(true);
    // }
  } 


  // const handleTriggerAction = async () => {
  //   const formData: any = {};
  
  //   // Select only the fields inside the specific div
  //   const formFields = document.querySelectorAll('.px-5 input');
  
  //   formFields.forEach((inputField: any) => {
  //     const fieldLabel = inputField.previousElementSibling?.innerText; // Assuming label is the previous sibling
  //     if (fieldLabel) {
  //       formData[fieldLabel] = inputField.value; // Capture the value
  //     }
  //   });
  
  //   try {
  //     formData['uuid'] = actionId;
  //     console.log("selectedInboxDetails: ", selectedInboxDetails);
  //     formData['in_uuid'] = selectedInboxDetails.in_uuid;
  //     console.log("formData: ", formData);
  //     const result = await updateTrack(formData); // Assuming createTrack is your API call function
  //     console.log("Process tracked successfully:", result);
  //     handleClose(); // Close the dialog after API call
  //   } catch (error) {
  //     console.error("Failed to track process:", error);
  //   }
  // };
  

  const handleTriggerAction = async () => {
    const formData: any = {};
  
    // Select all the form fields inside the specific div (including file inputs)
    const formFields = document.querySelectorAll('.px-5 input, .px-5 select, .px-5 textarea');
  
    formFields.forEach((inputField: any) => {
      // const fieldLabel = inputField.previousElementSibling?.innerText; // Assuming label is the previous sibling
      const fieldName = inputField.name; // Assuming label is the previous sibling
      if (fieldName) {
        // Handle file inputs separately
        if (inputField.type === 'file' && inputField.files.length > 0) {
          formData[fieldName] = inputField.files[0]; // Capture the first file selected
        } else {
          formData[fieldName] = inputField.value; // Capture text value for other fields
        }
      }
    });
  
    try {
      formData['uuid'] = actionId;
      console.log("selectedInboxDetails: ", selectedInboxDetails);
      formData['in_uuid'] = selectedInboxDetails.in_uuid;
      console.log("formData: ", formData);
  
      // Assuming updateTrack will handle multipart/form-data
      const result = await updateTrack(formData, formData);  // Pass both formData and file data
  
      console.log("Process tracked successfully:", result);
      handleClose(); // Close the dialog after API call
    } catch (error) {
      console.error("Failed to track process:", error);
    }
  };
  


  const formComponents = (item: any) => {
    const elements: any = {
      TEXT: (
        <InputField
          label={item.label}
          id={item.uuid}
          placeHolder={item.placeholder}
          onChange={() => {}}
        />
      ),
    };

    return elements[item.input_type];
  }

  useEffect(() => {
    console.log("Category: ", category.category)
    if (location.state?.display) {
      setIsShow(true);
    }else{
      setIsShow(false);
    }
    console.log("DISPLAY STATE: ", isShow);
    getInboxList();
  }, [category.category]);

  const getInboxList = async () => {
    try {
      const data = await listInbox(category.category);
      console.log(category);
      setinboxList(data);
      getInboxDetails(data[0].uuid);
    } catch (error) {
      console.log(error);
    }
  };

  const getInboxDetails = async (id: any) => {
    try {
      const data = await inboxDetails(id);    
      setSelectedInboxDetails(data);
      // setCommonData(data);
      // setHistoricData(data.history);
      // console.log("CommonData: ", commonData);
      // console.log("HistoricData: ", historicData);
      console.log("selectedInboxDetails: ", selectedInboxDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = (item: any) => {
    setDialogItem(item); // Set the current action item in dialog
    setActionId(item.uuid);
    setDialog(true);
  };

  return (
    <div className="flex flex-row h-full">
      <div className="border-r  border-gray-200 min-w-[310px]">
        <div className="p-4  flex flex-row items-center border-b">
          <Input
            name="search"
            onChange={() => {}}
            placeHolder="Search"
            prepend={<MdOutlineSearch size={25} />}
          />
          <button className="hover:bg-gray-300 ml-4 p-1 rounded-md">
            <MdFilterList size={25} />
          </button>
        </div>

        <div className="inbox-container">
          {Array.isArray(inboxList) &&
            inboxList?.map((section: any, index: any) => (
              <div
                key={section?.uuid}
                className={`inbox-item ${activeSectionIndex === index ? 'active' : ''}`}
                onClick={() => {
                  setIsShow(true);
                  setActiveSectionIndex(index);
                  getInboxDetails(section?.uuid);
                  setSelected(section);
                }}
              >
                <div className="inbox-item-content">
                  {/* <img src="/avatar.svg" alt="Avatar" className="inbox-item-image" /> */}
                  <div className="inbox-item-details">
                    <span className="inbox-item-label">{section?.node_name}</span>
                    {/* <span className="inbox-item-label">{section?.process_name}</span> */}
                    <span className="inbox-item-time">{section?.request_id}</span>
                    <span className="inbox-item-date">{section?.formatted_created_at}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>

      </div>
      <div className="w-full">
        <div className="w-full border-b p-5">
          {selected && (
            <div className="flex">
              <div className="px-2 overflow-hidden justify-center   ">
                {/* <img
                  src={"https://i.pravatar.cc/300"}
                  width={35}
                  height={35}
                  alt="icon"
                  className="rounded-full"
                /> */}
              </div>
              <div>
                <Typography variant="h5">{selected?.process_name}</Typography>
                <Typography variant="caption">
                  Request Id:{" "}
                  {`${selected?.request_id} | ${selected?.updated_at_date}  `}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {/* {selected && <div className="px-5 pt-5"> */}
        {selected && (
          // Only render EmailDetailCard if selectedInboxDetails is an array
          Array.isArray(selectedInboxDetails) && selectedInboxDetails.map((item: any) => (
              <EmailDetailCard
                  key={item?.uuid}
                  selectedInboxDetails={item}
                  selected={selected}
                  handleAction={handleAction}
              />
          ))
      )}

      
      {/* {selected && isShow &&(
          <EmailDetailCard
            selectedInboxDetails={selectedInboxDetails}
            // commonData={commonData}
            // historicData={historicData}
            selected={selected}
            handleAction={handleAction}
          />
        )} */}

      </div>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Conditionally render the message based on dialogItem.completed */}
            {dialogItem && !dialogItem.completed
              ? "Are you sure you want to trigger with this action?"
              : "This action is already triggered."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* Render Confirm button only if dialogItem.completed is false */}
          {dialogItem && !dialogItem.completed && (
            <Button onClick={handleTriggerAction}>Confirm</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}