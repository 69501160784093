import { AddOutlined } from "@mui/icons-material";
import { FC, useState, useEffect } from "react";
import Heading from "./Heading";
import InputField from "./InputField";
import { elements_type } from "../constants";

interface DropDownInterface {
  onDelete: () => void;
  onBlur: (value: string, field: string) => void;
  label?: string;
  options: string[]; // Now options is a list of strings
}

const DropDown: FC<DropDownInterface> = ({
  onDelete,
  onBlur,
  label = "",
  options = []
}) => {
  const [data, setData] = useState<{
    label: string;
    options: string[];
    input_type: string;
  }>({
    input_type: elements_type.DROPDOWN,
    label,
    options: options.length > 0 ? options : [""], // Initialize with empty string if empty
  });

  useEffect(() => {
    // Ensure options field is not empty on mount
    if (data.options.length === 0) {
      setData(prevData => ({ ...prevData, options: [""] }));
    }
  }, []);

  const handleChange = (value: string, index: number) => {
    const updatedOptions = data.options.map((opt, optIndex) =>
      optIndex === index ? value : opt
    );
    setData({ ...data, options: updatedOptions });
  };

  const addOption = () => {
    setData((state) => ({
      ...state,
      options: [...state.options, ""],
    }));
  };

  return (
    <div className="border-[1px] rounded">
      <Heading type={elements_type.DROPDOWN} onDelete={onDelete} />
      <div className="p-4">
        <InputField
          label="Dropdown Label"
          value={data.label}
          placeholder="Label for dropdown"
          onChange={(value) => setData({ ...data, label: value })}
          onBlur={(e) => onBlur(e, "label")}
        />
        <div className="mt-2">
          <h5>Options for Dropdown</h5>
          {data.options.map((option, index) => (
            <div className="flex justify-between mt-4 gap-x-2 items-center" key={index}>
              <InputField
                label="Option"
                placeholder="Value for Option"
                value={option}
                onChange={(value) => handleChange(value, index)}
                onBlur={(e) => onBlur(e, `${index}`)}
              />
              <button
                type="button"
                onClick={addOption}
                className="text-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full p-2.5 inline-flex items-center"
              >
                <AddOutlined />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
