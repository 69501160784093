import React, { FC, useState } from "react";
import Heading from "./Heading";
import InputField from "./InputField";
import { elements_type } from "../constants";

interface TextProps {
  title: string;
  placeholder: string;
  onDelete: () => void;
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
}

const Text: FC<TextProps> = ({ title, placeholder, onDelete, onBlur }) => {
  const [data, setData] = useState({
    label: "",
    placeholder: placeholder,
    element_type: "INP",
    input_type: elements_type.TITLE,
    title: title || "",
  });

  const handleChange = (value: string) => {
    const updatedData = {
      ...data,
      title: value,
    };
    setData(updatedData);
  };


  return (
    <div className="border-[1px] rounded-md bg-white">
      <Heading type={elements_type.TITLE} onDelete={onDelete} />
      <div className="p-4">
        <InputField
          value={data.title}
          label="Title"
          placeholder={data.title || "Enter title of the form here"}
          onBlur={onBlur}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Text;
